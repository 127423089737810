import styled from "styled-components"

import { bgColor, lgShadow, oldTheme } from "../../theme"

export const Container = styled.div`
  width: auto;
  height: auto;
  position: relative;
  background-color: ${bgColor};
  border-radius: 8px;
  box-shadow: ${lgShadow};
  padding: ${({ withPadding }) => (withPadding ? "10px" : "unset")};

  transition: background-color 0.2s ease-in-out;

  & + & {
    margin-left: 20px;
  }
`

export const AdaptiveContainer = styled(Container)`
  width: 100%;
  height: 100%;
`

export const PdfPages = styled.div`
  width: 1230px;
  height: 85vh;
  box-shadow: ${oldTheme.lgShadow};
`

export const TableContents = styled(PdfPages)``
