import React from "react"
import { BsCheckCircle, VscDebugDisconnect } from "react-icons/all"
import styled from "styled-components"

import { FlexCentered, FlexColumn, Text } from "../styled"
import { useLanguage, usePing } from "../../hooks"
import { Toast } from "./toast"

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

const ServerName = styled(Text)`
  display: block;
  width: 75px;
`

export function ToastConnectionInfo() {
  const { t } = useLanguage()
  const { isBackUp, isProxyUp } = usePing()

  return (
    <Toast shouldBeClosed={isBackUp && isProxyUp}>
      <FlexColumn>
        <FlexCentered>
          <Flex>
            <ServerName>{t("toast.server")}</ServerName>
            {isBackUp
              ? t("toast.status.connected")
              : t("toast.status.disconnected")}
          </Flex>
          {isBackUp ? <BsCheckCircle /> : <VscDebugDisconnect />}
        </FlexCentered>
        <FlexCentered
          css={`
            margin-top: 10px;
          `}
        >
          <Flex>
            <ServerName>{t("toast.proxy")}</ServerName>
            {isProxyUp
              ? t("toast.status.connected")
              : t("toast.status.disconnected")}
          </Flex>
          {isProxyUp ? <BsCheckCircle /> : <VscDebugDisconnect />}
        </FlexCentered>
      </FlexColumn>
    </Toast>
  )
}
