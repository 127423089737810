import styled, { css } from "styled-components"

import { bgColor } from "../../theme"

const { fgColor } = require("../../theme")

export const scrollStyling = inverted => css`
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ inverted }) => (inverted ? bgColor : fgColor)};
    border-radius: 6px;
  }
`

export const Scroll = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "calc(100% - 40px)")};
  overflow-x: auto;

  ${({ isColumn }) =>
    isColumn &&
    css`
      flex-direction: column;
    `}

  ${({ inverted }) => scrollStyling(inverted)}
`

export const ColumnContainer = styled.div`
  margin: 30px;
  width: ${({ w, custom }) => (w ? (custom ? w : `${w}px`) : "auto")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
