import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import countries from "i18n-iso-countries"
import en from "i18n-iso-countries/langs/en.json"
import ru from "i18n-iso-countries/langs/ru.json"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      ru: {
        translations: require("../locales/ru/translations.json"),
      },
      en: {
        translations: require("../locales/en/translations.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  })

countries.registerLocale(en)
countries.registerLocale(ru)

i18n.languages = ["ru", "en"]

export default i18n
