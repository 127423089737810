import React, { forwardRef } from "react"

import { Container, FlexColumnAdaptive } from "../styled"
import { DescriptionContainer } from "./description.styled"
import { useTransition } from "../../hooks"

export const Description = forwardRef(({ children, menuRef }, ref) => {
  const isShown = useTransition()

  return (
    <DescriptionContainer ref={ref} isShown={isShown}>
      <Container withPadding>
        <FlexColumnAdaptive>{children}</FlexColumnAdaptive>
      </Container>
    </DescriptionContainer>
  )
})

Description.displayName = "Description"
