import React from "react"

import { CheckboxContainer, CheckboxInput, CheckboxMark } from "../styled"

export function Checkbox({ checked, onChange, insideList }) {
  return (
    <CheckboxContainer insideList={insideList}>
      <CheckboxInput checked={checked} onChange={onChange} />
      <CheckboxMark />
    </CheckboxContainer>
  )
}
