import React from "react"

import { Scroll, Text } from "../styled"

export function NotificationText({ children }) {
  return (
    <Text inverted as="p" css={"margin-bottom: 5px"}>
      {Boolean(children) ? children : <br />}
    </Text>
  )
}

export function NotificationContent({ children }) {
  return (
    <Scroll inverted fullHeight>
      <Text
        as="div"
        css={`
          padding: 10px;
        `}
      >
        {children}
      </Text>
    </Scroll>
  )
}
