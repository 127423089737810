import styled from "styled-components"
import { CgDarkMode } from "react-icons/cg"
import { fgColor } from "../../theme"

export const DarkModeInverted = styled(CgDarkMode)`
  transform: scale(-1, 1);
  color: ${fgColor};
`

export const DarkMode = styled(CgDarkMode)`
  transform: scale(1, 1);
  float: right;
  color: ${fgColor};
`
