import styled, { css } from "styled-components"
import { FlexCentered } from "../styled"

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  ${({ fixed }) =>
    !fixed &&
    css`
      position: absolute;
      top: ${({ isMiddle }) => (isMiddle ? "120px" : "30px")};
      left: 30px;
    `}
  height: ${({ isMiddle }) => (isMiddle ? "auto" : "60px")};

  @media (max-width: 600px) {
    ${({ isMiddle }) =>
      isMiddle &&
      css`
        top: unset;
        bottom: ${({ isMiddle }) => isMiddle && "60px"};
      `}
  }
`

export const HorizontalContent = styled(FlexCentered)`
  justify-content: center;
  height: 60px;
`
