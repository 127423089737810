import React, { memo, useEffect, useState } from "react"
import styled from "styled-components"
import { Container, Text } from "../styled"
import { TOAST_CLOSE_DURATION, TOAST_OPEN_DURATION } from "../../constants"

const ToastContainer = styled.div`
  z-index: 4;
  position: absolute;
  top: ${({ isShown }) => (isShown ? "40px" : "-120px")};
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: auto;

  transition: top 0.5s ease-in-out;
`

export const Toast = memo(({ children, shouldBeClosed }) => {
  const [isShown, setShown] = useState(!shouldBeClosed)

  useEffect(() => {
    const animationTimer = setTimeout(
      () => setShown(!shouldBeClosed),
      shouldBeClosed ? TOAST_CLOSE_DURATION : TOAST_OPEN_DURATION
    )

    return () => {
      clearTimeout(animationTimer)
    }
  }, [shouldBeClosed])

  return (
    <ToastContainer isShown={isShown}>
      <Container withPadding>
        <Text>{children}</Text>
      </Container>
    </ToastContainer>
  )
})

Toast.displayName = "Toast"
