import { COUNTRIES } from "../components"
import { fixIsoCode } from "./fix-iso-code"
import { v4 } from "uuid"
import format from "date-fns/format"

export function generateRandomCountryNumber() {
  return Math.floor(Math.random() * 65 + 20)
}

export function generateInfo(number, logs) {
  return [...Array(number).keys()].map(() => {
    const countryIndex = Math.floor(Math.random() * COUNTRIES.features.length)
    const logIndex = Math.floor(Math.random() * logs.length)
    const logTextIndex = Math.floor(Math.random() * 3 + 1)
    const numbers = Math.floor(Math.random() * 25000 + 1)
    const text = {
      en: logs[logIndex][`log${logTextIndex}_en`].replace("{{}}", numbers),
      ru: logs[logIndex][`log${logTextIndex}_ru`].replace("{{}}", numbers),
    }

    const countryFeature = COUNTRIES.features[countryIndex].I

    return {
      country: fixIsoCode(countryFeature),
      value: Math.floor(Math.random() * 10000000),
      text,
    }
  })
}

export function generateLog(info) {
  const year = Math.random() * 60 + 1950
  const splicedAmount = Math.random() * 9 + 1

  return [...info].splice(0, splicedAmount).map(({ country, text }) => {
    const key = v4()
    const date = format(
      new Date(year, Math.random() * 12 + 1, Math.random() * 28 + 1),
      "MM.dd.yyyy"
    )

    return {
      key,
      date,
      text,
      country,
    }
  })
}
