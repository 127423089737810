import styled from "styled-components"

import { baseShadow, bgColor, darkenBgColor, fgColor } from "../../theme"
import { FlexCentered } from "../styled"

export const MenuItemContainer = styled(FlexCentered)`
  padding: 10px 20px;
  background-color: ${({ noTransform, isOdd }) =>
    noTransform && isOdd ? darkenBgColor : bgColor};
  box-shadow: ${({ noTransform }) => (noTransform ? "unset" : baseShadow)};
  margin-top: 4px;
  border-radius: ${({ noTransform }) => (noTransform ? "unset" : "6px")};
  color: ${fgColor};

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`

export const DescriptionOnHoverContainer = styled.div`
  z-index: 1;
  position: absolute;
  pointer-events: none;
  width: 90%;
  bottom: 0;
  padding: 10px;
  left: 50%;
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  transform: translate(-50%, 100%);
  background-color: ${darkenBgColor};
  border-radius: 6px;

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
`

export const Item = styled.div`
  width: 100%;
  max-width: ${({ isSmall }) => (isSmall ? "300px" : "500px")};

  @media (max-width: 520px) {
    width: 300px;
  }
`
