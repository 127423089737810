export function fixIsoCode(countryFeature) {
  switch (countryFeature) {
    case "US_child_path":
      return "US"
    case "RU_child_path":
      return "RU"
    case "FR_child_path":
      return "FR"
    case "NO_child_path":
      return "NO"
    default:
      return countryFeature
  }
}
