import React from "react"
import { BiLoaderAlt } from "react-icons/bi"
import styled from "styled-components"
import { bgColor, fgColor } from "../../theme"

export const LoaderContainer = styled.div`
  z-index: 2;
  text-align: center;
`

const RotatedIcon = styled(BiLoaderAlt)`
  color: ${({ inverted }) => (inverted ? bgColor : fgColor)};
  animation: spin 2s linear infinite;

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
`

export function Loader({ invert }) {
  return (
    <LoaderContainer>
      <RotatedIcon fontSize={60} inverted={invert} />
    </LoaderContainer>
  )
}
