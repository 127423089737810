const reformattedData = {
  bomb: null,
  spaceObject: null,
  anthropogenic: null,
  virus: null,
}

export function makeFeatureData(featureName, data) {
  switch (featureName) {
    case "Atomic bomb":
      return {
        ...reformattedData,
        bomb: data.value,
      }
    case "Virus":
      return {
        ...reformattedData,
        virus: {
          classVirus: {
            id: data.value,
            symptoms: data.symptoms,
          },
          name: data.virus,
          incPeriod: data.period,
          contagiousness: data.cont,
        },
      }
    case "Celestial body":
      return {
        ...reformattedData,
        spaceObject: {
          id: data.spaceObject?.value || null,
          material: data.material.value,
        },
      }
    case "Man-made disaster":
      return {
        ...reformattedData,
        anthropogenic: {
          chemicalSubstance: data.value,
          daypart: data.daypart.value,
          atmospheric: data.atmospheric.value,
          windSpeed: Number(data.windSpeed),
          airTemperature: Number(data.airTemperature),
          masks: Number(data.masks),
          weight: Number(data.weight),
        },
      }

    default:
      return reformattedData
  }
}
