import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react"
import _ from "lodash"
import differenceInDays from "date-fns/differenceInDays"
import addDays from "date-fns/addDays"
import { useSelector } from "react-redux"

import { calculateSimulation, drawCircle } from "../utils"
import { oldTheme } from "../theme"

const initialSimulation = {
  deaths: 0,
  victim: 0,
  radius: 0,
  time: Date.now(),
}

export function useSimulation({ isLoaded, mapObject }) {
  const requestRef = useRef()
  const timeRef = useRef()
  const radiusRef = useRef()

  const {
    epicenter,
    casualty,
    influence,
    latitude,
    longitude,
    deaths: deathsDamage,
    victim: victimDamage,
    period: periodDamage, // в днях
    startDeaths, // в секундах
    epicenterDeaths, // количество максимально в эпицентре
    casualtyDeaths,
    influenceDeaths,
    epicenterVictim,
    casualtyVictim,
    influenceVictim,
  } = useSelector(({ damage }) => damage)

  const [needToReset, setNeedToReset] = useState(false)
  const [isStarted, setStarted] = useState(false)

  const [values, setValues] = useState(initialSimulation)

  const polygons = [
    { name: "epicenter", value: epicenter, opacity: 0.6 },
    { name: "casualty", value: casualty, opacity: 0.4 },
    { name: "influence", value: influence, opacity: 0.2 },
  ]

  const maxRadius = useMemo(
    () => polygons.reduce((max, { value }) => Math.max(max, value), 0),
    [polygons]
  )

  const period = useMemo(() => addDays(Date.now(), periodDamage + 1), [
    periodDamage,
  ])

  const resetMap = useCallback(() => {
    cancelAnimationFrame(requestRef.current)
    polygons.forEach(({ name }) => {
      if (mapObject.getSource(name)) {
        mapObject.removeLayer(name)
        mapObject.removeSource(name)
      }
    })
  }, [mapObject, polygons, requestRef])

  const startSimulation = useCallback(() => {
    setValues(({ deaths, victim, radius, time }) => {
      polygons.forEach(({ name, value }) => {
        mapObject.getSource(name) &&
          mapObject
            .getSource(name)
            .setData(
              drawCircle([latitude, longitude], Math.min(radius, value)).data
            )
      })

      const { newDeaths, newVictims, newTime, newRadius } = calculateSimulation(
        {
          epicenter: polygons[0].value,
          casualty: polygons[1].value,
          influence: polygons[2].value,
        },
        { deaths, victim, radius, time, initialTime: timeRef.current },
        {
          maxRadius,
          period,
          periodDamage,
        },
        {
          startDeaths,
          epicenterDeaths,
          casualtyDeaths,
          influenceDeaths,
          epicenterVictim,
          casualtyVictim,
          influenceVictim,
        }
      )

      if (
        !(
          newDeaths === deathsDamage &&
          newVictims === victimDamage &&
          newRadius === maxRadius &&
          differenceInDays(newTime, timeRef.current) === periodDamage
        )
      ) {
        requestRef.current = requestAnimationFrame(startSimulation)
      }

      radiusRef.current = newRadius

      return {
        deaths: Math.ceil(newDeaths),
        victim: Math.ceil(newVictims),
        radius: newRadius,
        time: newTime,
      }
    })
  }, [
    polygons,
    latitude,
    longitude,
    mapObject,
    deathsDamage,
    victimDamage,
    maxRadius,
    period,
    periodDamage,
    startDeaths,
    epicenterDeaths,
    casualtyDeaths,
    influenceDeaths,
    epicenterVictim,
    casualtyVictim,
    influenceVictim,
  ])

  const drawCircles = useCallback(
    explicitValue => {
      polygons.forEach(({ name, value, opacity }) => {
        if (mapObject.getSource(name)) {
          return
        }
        mapObject.addSource(
          name,
          drawCircle(
            [latitude, longitude],
            explicitValue ? Math.min(explicitValue, value) : value
          )
        )
        mapObject.addLayer({
          id: name,
          type: "fill",
          source: name,
          layout: {},
          paint: {
            "fill-color": oldTheme[name],
            "fill-opacity": opacity,
          },
        })
      })
    },
    [polygons, latitude, longitude, mapObject]
  )

  const handleStart = useCallback(() => {
    if (!isLoaded) {
      return
    }
    setStarted(true)
    timeRef.current = timeRef.current || Date.now()

    mapObject.flyTo({
      center: [longitude, latitude],
      zoom: 9,
      bearing: 0,
      speed: 1,
      curve: 1,
      easing: t => t,
      essential: true,
    })

    if (!requestRef.current) {
      drawCircles()
    }
    startSimulation()
  }, [
    requestRef,
    drawCircles,
    startSimulation,
    mapObject,
    isLoaded,
    latitude,
    longitude,
  ])

  const handlePause = useCallback(() => {
    setStarted(false)
    cancelAnimationFrame(requestRef.current)
  }, [requestRef])

  const handleReset = useCallback(() => {
    if (!isLoaded) {
      return
    }
    resetMap()
    requestRef.current = null
    timeRef.current = null
    setValues({ ...initialSimulation, time: Date.now() })
    setNeedToReset(false)
    setStarted(false)
  }, [isLoaded, resetMap])

  const handleFinish = useCallback(() => {
    if (!isLoaded) {
      return
    }
    resetMap()
    drawCircles()
    setValues({
      deaths: deathsDamage,
      victim: victimDamage,
      radius: influence,
      time: period,
    })
    setStarted(false)
    setNeedToReset(true)
  }, [
    isLoaded,
    resetMap,
    drawCircles,
    deathsDamage,
    victimDamage,
    period,
    influence,
  ])

  useLayoutEffect(() => {
    if (!isLoaded) {
      cancelAnimationFrame(requestRef.current)
    }

    if (!_.isEqual(_.omit(values, "time"), _.omit(initialSimulation, "time"))) {
      drawCircles(radiusRef.current)
      if (isStarted) {
        startSimulation()
      }
    }

    return () => cancelAnimationFrame(requestRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, requestRef, isStarted])

  return {
    handleStart,
    handlePause,
    handleReset,
    handleFinish,
    values,
    needToReset,
  }
}
