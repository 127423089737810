import styled, { css } from "styled-components"
import { Centered } from "../styled"

export const ModalContainer = styled(Centered)`
  top: 51%;
  width: 600px;
  height: ${({ fixed, autoHeight }) =>
    fixed && !autoHeight ? "80vh" : "auto"};

  @media (max-width: 600px) {
    width: 400px;
  }

  @media (max-width: 400px) {
    width: 200px;
  }

  ${({ fixed }) =>
    !fixed &&
    css`
      top: 150px;
      transform: translate(-50%, 0);
    `}
`
