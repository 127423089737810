import React, { memo } from "react"
import countries from "i18n-iso-countries"
import { geoMercator, geoPath } from "d3-geo"
import { PathTooltip } from "react-path-tooltip"
import _ from "lodash"

import { COUNTRIES } from "./countries"
import { oldTheme } from "../../theme"
import { fixIsoCode } from "../../utils"
import {
  WorldMapContainer,
  WorldMapContainerFixed,
} from "./world-map-container.styled"
import { useWindowSize, useLanguage } from "../../hooks"

export const WorldMap = memo(({ isLoaded, data, container, onClick }) => {
  const { lang } = useLanguage()
  const size = useWindowSize(container)

  const scale = (0.7 / 480) * size.w

  const transformPaths = container?.current
    ? `scale(${scale}) translate (50, 200)`
    : `scale(${scale}) translate (-200, ${
        size.w >= size.h ? size.w / size.h : size.h / size.w
      })`

  const containerRef = React.createRef()

  const defaultCountryStyle = (context, single) => {
    const contextCountryValue = context.countryValue ? context.countryValue : 0
    const opacityLevel = single
      ? 1
      : 0.2 +
        (0.6 * (contextCountryValue - context.minValue)) /
          (context.maxValue - context.minValue)
    return {
      fill: context.color,
      fillOpacity:
        contextCountryValue === 0 ? contextCountryValue : opacityLevel,
      stroke: oldTheme.fgColorSecondary,
      strokeWidth: 1,
      strokeOpacity: 0.8,
      cursor: "pointer",
    }
  }

  const countryValueMap = {}
  const countryValueText = {}
  let max = -Infinity
  let min = Infinity
  data.forEach(entry => {
    const key = entry["country"].toUpperCase()
    const { value, text } = entry
    min = min > value ? value : min
    max = max < value ? value : max
    countryValueMap[key] = value
    countryValueText[key] = text
  })

  const pathGenerator = geoPath().projection(geoMercator())

  const pathsAndTooltips = COUNTRIES.features.map((feature, idx) => {
    const triggerRef = React.createRef()
    const isoCode = fixIsoCode(feature.I)

    const geoFeature = {
      type: "Feature",
      properties: { ISO_A2: isoCode },
      geometry: {
        type: "MultiPolygon",
        coordinates: feature.C,
      },
    }
    const isHighlight = typeof countryValueMap[isoCode] != "undefined"
    const style = defaultCountryStyle(
      {
        country: isoCode,
        countryValue: countryValueMap[isoCode],
        color: oldTheme.warningColor,
        minValue: min,
        maxValue: max,
      },
      data.length === 1
    )

    const path = (
      <path
        key={`path_${idx}`}
        ref={triggerRef}
        d={pathGenerator(geoFeature)}
        style={style}
        onMouseOver={event => {
          event.currentTarget.style.strokeWidth = "2"
          event.currentTarget.style.strokeOpacity = "1"
        }}
        onMouseOut={event => {
          event.currentTarget.style.strokeWidth = "1"
          event.currentTarget.style.strokeOpacity = "0.8"
        }}
        onClick={onClick ? onClick(isoCode) : _.noop}
      />
    )

    const tooltip =
      isHighlight || onClick ? (
        <PathTooltip
          fontSize={14}
          bgColor={oldTheme.fgColor}
          textColor={oldTheme.bgColor}
          key={`path_${idx}_xyz`}
          pathRef={triggerRef}
          svgRef={containerRef}
          tip={`${countries.getName(isoCode, lang, { select: "official" })} ${
            !!countryValueText[isoCode] ? countryValueText[isoCode][lang] : ""
          }`}
        />
      ) : (
        <g pointerEvents="none" key={`path_${idx}_xyz`} />
      )

    return {
      path,
      highlightedMarkerOrTooltip: tooltip,
    }
  })

  const paths = pathsAndTooltips.map(entry => {
    return entry.path
  })

  const markersOrTooltips = pathsAndTooltips.map(entry => {
    return entry.highlightedMarkerOrTooltip
  })

  return container?.current ? (
    <WorldMapContainerFixed isLoaded={isLoaded}>
      <svg
        ref={containerRef}
        css={`
          width: 530px;
          height: 320px;
        `}
      >
        <g transform={transformPaths}>{paths}</g>
        {markersOrTooltips}
      </svg>
    </WorldMapContainerFixed>
  ) : (
    <WorldMapContainer isLoaded={isLoaded}>
      <svg
        ref={containerRef}
        css={`
          width: ${size.w * 2}px;
          height: ${size.h}px;
        `}
      >
        <g transform={transformPaths}>{paths}</g>
        {markersOrTooltips}
      </svg>
    </WorldMapContainer>
  )
})
