import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GiNightSky, TiWeatherWindyCloudy } from "react-icons/all"

import { useLanguage } from "../../../hooks"
import {
  setAirTemperature,
  setAtmospheric,
  setDaypart,
  setMasks,
  setWeight,
  setWindSpeed,
} from "../../../data/actions"
import { iconProps } from "../../../constants"
import { SettingsMenuItem } from "../menu"
import { Range } from "../../range"
import { plural } from "../../../utils"

export function Anthropogenic({ receivedData, handlers }) {
  const dispatch = useDispatch()
  const { handleDispatch, handleLoad } = handlers
  const { t, lang } = useLanguage()
  const {
    name,
    feature,
    daypart,
    atmospheric,
    windSpeed,
    airTemperature,
    masks,
    weight,
  } = useSelector(({ settings }) => settings)

  const handleChange = useCallback(
    action => e => dispatch(action(e.target.value)),
    [dispatch]
  )

  return (
    name &&
    name.name.en === "Man-made disaster" &&
    feature && (
      <>
        <SettingsMenuItem
          title={t(`settings.disaster.Man-made disaster.daypart.value`)}
          description={
            (daypart && daypart.name[lang]) ||
            t(`settings.disaster.Man-made disaster.daypart.description`)
          }
          notFilled={!daypart || !daypart.name[lang]}
          data={receivedData.dayparts}
          id="idDaypart"
          onLoad={handleLoad("daypart")}
          onClick={handleDispatch(setDaypart)}
          icon={<GiNightSky {...iconProps} />}
        />
        <SettingsMenuItem
          title={t(`settings.disaster.Man-made disaster.atmospheric.value`)}
          description={
            (atmospheric && atmospheric.name[lang]) ||
            t(`settings.disaster.Man-made disaster.atmospheric.description`)
          }
          notFilled={!atmospheric || !atmospheric.name[lang]}
          data={receivedData.atmospherics}
          id="idAtmospheric"
          onLoad={handleLoad("atmospheric")}
          onClick={handleDispatch(setAtmospheric)}
          icon={<TiWeatherWindyCloudy {...iconProps} />}
        />
        <Range
          min={0}
          max={10}
          step={1}
          value={windSpeed}
          onChange={handleChange(setWindSpeed)}
          label={t("settings.anthropogenic.windSpeed")}
          labelValue={windSpeed}
        />
        <Range
          min={-30}
          max={30}
          step={10}
          value={airTemperature}
          onChange={handleChange(setAirTemperature)}
          label={t("settings.anthropogenic.airTemperature")}
          labelValue={`${airTemperature} °C`}
        />
        <Range
          min={0}
          max={100}
          step={20}
          value={masks}
          onChange={handleChange(setMasks)}
          label={t("settings.anthropogenic.masks")}
          labelValue={`${masks} %`}
        />
        <Range
          min={1}
          max={1000}
          step={1}
          value={weight}
          onChange={handleChange(setWeight)}
          label={t("settings.anthropogenic.weight")}
          labelValue={`${weight} ${plural(
            weight,
            {
              ru: ["тонна", "тонны", "тонн"],
              en: ["ton", "tons"],
            },
            lang
          )}`}
        />
      </>
    )
  )
}
