import { useTranslation } from "react-i18next"
import { useCallback, useMemo } from "react"

export function useLanguage() {
  const { t, i18n } = useTranslation()

  const [lang] = useMemo(() => {
    let language = i18n.language.match(/en|ru/)
    return language ? language : []
  }, [i18n.language])

  const setLanguage = useCallback(lang => () => i18n.changeLanguage(lang), [
    i18n,
  ])

  return {
    t,
    lang,
    setLanguage,
  }
}
