import { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import axios from "axios"

import {
  externalAddress,
  externalProxy,
  localAddress,
  PING_TIMEOUT,
} from "../constants"
import { setBackUp, setProxyUp } from "../data/actions"

export function usePing() {
  const dispatch = useDispatch()
  const isLocal = useSelector(({ address }) => address, shallowEqual)
  const { isBackUp, isProxyUp } = useSelector(
    ({ connection: { isBackUp, isProxyUp } }) => ({
      isBackUp,
      isProxyUp,
    }),
    shallowEqual
  )
  const apiAddress = isLocal ? localAddress : externalAddress

  useEffect(() => {
    const pingInterval = setInterval(() => {
      axios
        .get(`${externalProxy}/ping`)
        .then(({ data }) => {
          dispatch(setProxyUp(data === true))
        })
        .catch(() => dispatch(setProxyUp(false)))

      axios
        .get(`${apiAddress}/ping`)
        .then(({ data }) => {
          dispatch(setBackUp(data === true))
        })
        .catch(() => dispatch(setBackUp(false)))
    }, PING_TIMEOUT)

    return () => clearInterval(pingInterval)
  }, [dispatch, apiAddress])

  return {
    isBackUp,
    isProxyUp,
  }
}
