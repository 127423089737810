import React, { memo, useCallback, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import { shallowEqual, useSelector } from "react-redux"

import { externalProxy, localAddress } from "../../constants"
import { Loader } from "../loader"
import { FlexColumnAdaptive } from "../styled"
import { useLanguage, useWindowSize } from "../../hooks"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const Pdf = memo(({ onLoad }) => {
  const { lang } = useLanguage()
  const isLocal = useSelector(({ address }) => address, shallowEqual)
  const { isMobile, w } = useWindowSize()

  const [numPages, setNumPages] = useState(null)

  const handleLoad = useCallback(
    ({ numPages }) => {
      setNumPages(numPages)
      onLoad()
    },
    [onLoad]
  )

  return (
    <Document
      file={{
        url: `${isLocal ? localAddress : externalProxy}/howto_${lang}.pdf`,
      }}
      onLoadSuccess={handleLoad}
      loading={<Loader />}
      height={400}
      onLoadError={console.error}
    >
      <FlexColumnAdaptive noPadding>
        {[...Array(numPages).keys()].map(
          (page, idx) =>
            Boolean(page) && (
              <Page
                key={idx}
                pageNumber={page}
                width={isMobile ? w - 60 : 1200}
              />
            )
        )}
      </FlexColumnAdaptive>
    </Document>
  )
})

Pdf.displayName = "Pdf"
