import React from "react"

import { DescriptionOnHoverContainer } from "./menu-item.styled"
import { Text } from "../../components"
import { useTransition } from "../../hooks"

export function DescriptionOnHover({ description }) {
  const isShown = useTransition()

  return (
    <DescriptionOnHoverContainer isShown={isShown}>
      <Text>{description}</Text>
    </DescriptionOnHoverContainer>
  )
}
