import styled from "styled-components"

export const FlexCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FlexColumn = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const ButtonGroup = styled(FlexCentered)`
  margin-left: 20px;
  justify-content: space-around;

  @media (max-width: 520px) {
    margin-left: 0;
  }
`

export const FlexColumnAdaptive = styled(FlexColumn)`
  box-sizing: border-box;
  padding: ${({ noPadding }) => (noPadding ? "0" : "0 10px")};
  height: 100%;
  justify-content: flex-start;
`
