import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { FlexColumn, Text, TextSecondary } from "../styled"
import { DescriptionOnHover } from "./description-on-hover"
import { Item, MenuItemContainer } from "./menu-item.styled"
import { useHover } from "../../hooks"

const Icon = styled.div`
  width: 26px;
  height: 26px;
`

export function MenuItem({
  title,
  description,
  icon,
  isOdd,
  noTransform,
  noDescription,
  descriptionOnHover,
  warning,
}) {
  const containerRef = useRef(null)
  const [isSmall, setSmall] = useState(false)
  const { isHover, handleHoverIn, handleHoverOut } = useHover()

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const { width } = containerRef.current.getBoundingClientRect()
    setSmall(width < 400)
  }, [containerRef])

  return (
    <>
      <MenuItemContainer
        ref={containerRef}
        noTransform={noTransform}
        isOdd={isOdd}
        onMouseEnter={handleHoverIn}
        onMouseLeave={handleHoverOut}
      >
        <Item isSmall={isSmall}>
          <FlexColumn>
            <Text>{title}</Text>
            {!noDescription && !descriptionOnHover && (
              <TextSecondary notMobile warning={warning}>
                {description}
              </TextSecondary>
            )}
          </FlexColumn>
        </Item>
        {!!icon && <Icon>{icon}</Icon>}
      </MenuItemContainer>
      {descriptionOnHover && Boolean(description) && isHover && (
        <DescriptionOnHover description={description} />
      )}
    </>
  )
}
