import React from "react"

import {
  FlexCentered,
  FlexColumn,
  RangeContainer,
  RangeInput,
  Text,
} from "../styled"

export function Range({ value, onChange, min, max, step, label, labelValue }) {
  return (
    <RangeContainer>
      <FlexColumn>
        <FlexCentered>
          <Text>{label}</Text>
          <Text>{labelValue}</Text>
        </FlexCentered>
        <RangeInput
          step={step}
          max={max}
          min={min}
          value={value}
          onChange={onChange}
        />
      </FlexColumn>
    </RangeContainer>
  )
}
