import styled, { css } from "styled-components"

import {
  baseShadow,
  bgColor,
  darkenBgColor,
  fgColor,
  oldTheme,
  warningColor,
} from "../../theme"

export const InputText = styled.input.attrs(() => ({
  type: "text",
}))`
  width: 100%;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: ${fgColor};
  background-color: ${bgColor};
  border-radius: 8px;
  padding: 10px 20px;
  height: 64px;
  border: none;
  margin-top: 4px;
  box-shadow: ${({ noShadow }) => (noShadow ? "none" : baseShadow)};

  transition: transform 0.3s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &::placeholder {
    color: ${warningColor};
  }

  &:hover {
    transform: ${({ noTransform }) =>
      noTransform ? "unset" : "scale(1.1, 1.2)"};
  }

  &:focus {
    outline: none;
    transform: ${({ noTransform }) =>
      noTransform ? "unset" : "scale(1.1, 1.2)"};
  }
`

export const RangeInput = styled.input.attrs(() => ({
  type: "range",
}))`
  -webkit-appearance: none;
  color: inherit;
  background-color: inherit;

  &:focus {
    outline: none;

    &::-webkit-slider-runnable-track {
      transform: ${({ noTransform }) =>
        noTransform ? "unset" : "scale(1.1, 1.2)"};
    }
  }

  &:hover {
    &::-webkit-slider-runnable-track {
      transform: ${({ noTransform }) =>
        noTransform ? "unset" : "scale(1.1, 1.2)"};
    }
  }

  &::-webkit-slider-runnable-track {
    margin-top: 10px;
    background-color: ${darkenBgColor};
    border-radius: 8px;
    transition: transform 0.3s ease-in-out, background-color 0.2s ease-in-out;
  }

  &::-webkit-slider-thumb {
    margin-top: -10px;
    width: 10px;
    height: 20px;
    background: ${fgColor};
    border-radius: 8px;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background 0.2s ease-in-out;
  }
`

export const RangeContainer = styled.div`
  box-sizing: border-box;
  -webkit-appearance: none;
  padding: 10px 20px 0 20px;
  height: 64px;
  color: ${fgColor};
  background-color: ${bgColor};
  margin-top: 4px;
  box-shadow: ${baseShadow};

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`

export const CheckboxMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: ${darkenBgColor};

  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`

export const CheckboxInput = styled.input.attrs(() => ({
  type: "checkbox",
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const CheckboxContainer = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  width: 20px;
  height: 20px;
  user-select: none;

  ${({ insideList }) =>
    insideList &&
    css`
      position: absolute;
      display: block;
      left: 0;
      top: 0;
    `}
  &:hover ${CheckboxInput} ~ ${CheckboxMark} {
    box-shadow: ${oldTheme.highlight};
  }

  &:focus ${CheckboxInput} ~ ${CheckboxMark} {
    box-shadow: ${oldTheme.highlight};
  }

  & ${CheckboxInput}:checked ~ ${CheckboxMark} {
    background-color: ${fgColor};
  }

  & ${CheckboxInput}:checked ~ ${CheckboxMark}:after {
    display: block;
  }

  & ${CheckboxMark}:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid ${bgColor};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);

    transition: border 0.2s ease-in-out;
  }
`
