import React from "react"
import { useTable } from "react-table"

import { useLanguage } from "../../hooks"
import { TableContainer } from "./report.styled"

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export function Report({ data }) {
  const { t } = useLanguage()
  const columns = React.useMemo(
    () => [
      {
        Header: t("reports.result.disaster"),
        columns: [
          {
            Header: t("settings.disaster.name.value"),
            accessor: "name",
          },
          {
            Header: t("reports.result.virus"),
            accessor: "virus",
          },
          {
            Header: t("reports.result.virusProps"),
            accessor: "virusProps",
          },
          {
            Header: t("settings.disaster.Atomic bomb.value"),
            accessor: "bomb",
          },
          {
            Header: t("settings.disaster.power.value"),
            accessor: "power",
          },
          {
            Header: t("settings.disaster.duration.value"),
            accessor: "duration",
          },
          {
            Header: t("settings.disaster.scale.value"),
            accessor: "scale",
          },
          {
            Header: t("settings.disaster.country.value"),
            accessor: "country",
          },
          {
            Header: t("settings.disaster.region.value"),
            accessor: "region",
          },
        ],
      },
      {
        Header: t("reports.result.title"),
        columns: [
          {
            Header: t("reports.result.deaths"),
            accessor: "deaths",
          },
          {
            Header: t("reports.result.victims"),
            accessor: "victim",
          },
        ],
      },
      {
        Header: t("reports.result.date"),
        accessor: "date",
      },
      {
        Header: t("reports.result.start"),
        accessor: "start",
      },
    ],
    [t]
  )

  return (
    <TableContainer
      css={`
        margin-top: 30px;
      `}
    >
      <Table columns={columns} data={data} />
    </TableContainer>
  )
}
