import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { setAddress } from "../../data/actions"
import { Checkbox } from "../checkbox"

export function CheckboxLocal() {
  const dispatch = useDispatch()
  const isLocal = useSelector(({ address }) => address)

  const handleChange = useCallback(() => dispatch(setAddress()), [dispatch])

  return <Checkbox checked={isLocal} onChange={handleChange} />
}
