import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import { toggleDarkTheme } from "../data/actions"

export function useTheme() {
  const dispatch = useDispatch()
  const darkThemeEnabled = useSelector(({ theme }) => theme)
  const changeTheme = useCallback(() => {
    dispatch(toggleDarkTheme())
  }, [dispatch])

  return {
    darkThemeEnabled,
    changeTheme,
  }
}
