import React, { useRef } from "react"

import { Img, TextSecondary, Title } from "../../styled"
import { WorldMap } from "../../world-map"
import { Description } from "../../description"
import { useSelector } from "react-redux"
import { useLanguage } from "../../../hooks"

export function DescriptionInfo({ mapData, onClick }) {
  const { lang } = useLanguage()
  const descriptionRef = useRef(null)
  const { name, power, duration, scale, disaster } = useSelector(
    ({ settings }) => settings
  )

  return (
    <Description ref={descriptionRef}>
      <Title fontSize={24}>{name.name[lang]}</Title>
      {disaster && <Img src={disaster.url} alt={name.name[lang]} />}
      {disaster && (
        <TextSecondary fontSize={18} lineHeight={1.3}>
          {disaster[`description_${lang}`]}.{" "}
          {power && `${power.description[lang]}. `}
          {duration && `${duration.description[lang]}. `}
          {scale && `${scale.description[lang]}.`}
        </TextSecondary>
      )}
      <WorldMap
        isLoaded
        data={mapData}
        container={descriptionRef}
        onClick={onClick}
      />
    </Description>
  )
}
