import theme from "styled-theming"

export const oldTheme = {
  fgColor: "#151926",
  fgColorSecondary: "#929cad",
  warningColor: "#e3a09e",
  bgColor: "#f7f8f9",
  white: "#f9fafa",
  hoverWhite: "#ffffff",
  lgShadow: "0 20px 20px 20px rgba(0, 0, 0, 0.1)",
  baseShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  highlight: "0 0 2px 3px #e3a09e",
  light: "#f1f3f1",
  dark: "#2c2c2b",
  epicenter: "#e63946",
  casualty: "#e5989b",
  influence: "#ffb4a2",
}

export const fgColor = theme("theme", {
  light: "#6b6b6b",
  dark: "#c4c4c4",
})

export const fgColorSecondary = theme("theme", {
  light: "#b1b1b1",
  dark: "#919191",
})

export const warningColor = theme("theme", {
  light: "#e3a09e",
  dark: "#e3a09e",
})

export const bgColor = theme("theme", {
  light: "#f1f3f1",
  dark: "#2c2c2b",
})

export const darkenBgColor = theme("theme", {
  light: "#d6dcd6",
  dark: "#6c6c69",
})

export const hover = theme("theme", {
  light: "#f6f6f4",
  dark: "#444444",
})

export const lgShadow = theme("theme", {
  light: "0 20px 20px 20px rgba(0, 0, 0, 0.1)",
  dark: "0 20px 20px 20px rgba(0, 0, 0, 0.1)",
})

export const baseShadow = theme("theme", {
  light: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  dark: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
})
