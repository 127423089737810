import React from "react"
import {
  AiFillCloseCircle,
  AiFillInfoCircle,
  AiOutlineCloseCircle,
  AiOutlineInfoCircle,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
  RiAlarmWarningFill,
  RiAlarmWarningLine,
  TiArrowBack,
  TiArrowBackOutline,
  TiDownload,
  TiDownloadOutline,
  TiMediaFastForward,
  TiMediaFastForwardOutline,
  TiMediaPause,
  TiMediaPauseOutline,
  TiMediaPlay,
  TiMediaPlayOutline,
  TiMediaStop,
  TiMediaStopOutline,
  AiFillWarning,
  AiOutlineWarning,
  BsBell,
  BsBellFill,
  GiBleedingEye,
} from "react-icons/all"

import { Button } from "../styled"
import { useHover } from "../../hooks"

const size = {
  fontSize: 36,
}

const smallSize = {
  fontSize: 18,
}

const footerSize = {
  fontSize: 16,
}

const icons = {
  play: <TiMediaPlay {...size} />,
  playInverted: <TiMediaPlayOutline {...size} />,
  forward: <TiMediaFastForward {...size} />,
  forwardInverted: <TiMediaFastForwardOutline {...size} />,
  pause: <TiMediaPause {...size} />,
  pauseInverted: <TiMediaPauseOutline {...size} />,
  stop: <TiMediaStop {...size} />,
  stopInverted: <TiMediaStopOutline {...size} />,
  back: <TiArrowBack {...size} />,
  backInverted: <TiArrowBackOutline {...size} />,
  download: <TiDownload {...size} />,
  downloadInverted: <TiDownloadOutline {...size} />,
  close: <AiFillCloseCircle {...size} />,
  closeInverted: <AiOutlineCloseCircle {...size} />,
  sortAsc: <AiOutlineSortAscending {...size} />,
  sortDesc: <AiOutlineSortDescending {...size} />,
  sortAscInverted: <AiOutlineSortAscending {...size} />,
  sortDescInverted: <AiOutlineSortDescending {...size} />,
  info: <AiFillInfoCircle {...smallSize} />,
  infoInverted: <AiOutlineInfoCircle {...smallSize} />,
  warning: <AiFillWarning {...smallSize} />,
  warningInverted: <AiOutlineWarning {...smallSize} />,
  error: <RiAlarmWarningFill {...smallSize} />,
  errorInverted: <RiAlarmWarningLine {...smallSize} />,
  notification: <BsBell {...footerSize} />,
  notificationInverted: <BsBellFill {...footerSize} />,
  reportsPlay: <GiBleedingEye {...smallSize} />,
  reportsPlayInverted: <GiBleedingEye {...smallSize} />,
}

export function IconButton({
  onClick,
  iconName,
  disabled,
  active,
  needToPress,
  customColor,
  autoWidth,
}) {
  const { isHover, handleHoverIn, handleHoverOut } = useHover()

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      needToPress={needToPress}
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
      onFocus={handleHoverIn}
      onBlur={handleHoverOut}
      customColor={customColor}
      active={active}
      autoWidth={autoWidth}
    >
      {isHover || active ? icons[`${iconName}Inverted`] : icons[iconName]}
    </Button>
  )
}
