import styled from "styled-components"

import { DURATION } from "../../constants"
import { bgColor } from "../../theme"

export const FullWidth = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ isModal }) =>
    isModal ? "rgba(0, 0, 0, .8)" : bgColor};
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  transition: opacity ${DURATION}ms ease-in-out;
`
