import styled from "styled-components"
import { darkenBgColor, fgColor } from "../../theme"

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${fgColor};
  
  @media (max-width: 1120px) {
    width: unset;
    align-items: flex-start;
  }

    table {
    display: block;
    position: relative;
    border-collapse: separate;
    border-spacing: 0;
    border-left: 1px solid ${fgColor};

    thead {
      font-weight: 600;

      & th {
        position: sticky;
        background-color: ${darkenBgColor};
        transition: background-color 0.2s ease-in-out;
      }

      & tr:first-child th {
        top: 0;
      }

      & tr:last-child th {
        top: 32px;
      }
    }

    th {
      border-top: 1px solid ${fgColor};
      border-bottom: 1px solid ${fgColor};
      border-right: 1px solid ${fgColor};
    }

    td {
      border-bottom: 1px solid ${fgColor};
      border-right: 1px solid ${fgColor};
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
    }
  }
}
`
