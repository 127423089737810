import styled from "styled-components"

export const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const TopRight = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  width: auto;
  height: auto;
`
export const TopMiddle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
`
