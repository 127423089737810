import differenceInSeconds from "date-fns/differenceInSeconds"
import addMinutes from "date-fns/addMinutes"
import isBefore from "date-fns/isBefore"

import { SIMULATION_SPEED } from "../constants"

function increment(value, maxValue) {
  const newValue = value + maxValue / SIMULATION_SPEED

  return Math.min(newValue, maxValue)
}

function calculateDeathsAndVictims(radius, values, conditions) {
  const { inEpicenter, inCasualty, inInfluence } = radius
  const [value] = values
  const [epicenterMax, casualtyMax, influenceMax] = conditions
  const casualtySum = epicenterMax + casualtyMax

  const influenceSum = epicenterMax + casualtyMax + influenceMax
  let result = value

  if (inInfluence) {
    result = increment(value, influenceSum)
  }
  if (inCasualty) {
    result = increment(value, casualtySum)
  }
  if (inEpicenter) {
    result = increment(value, epicenterMax)
  }

  return result
}

function calculateTime(values, conditions) {
  const { time, period, periodDamage } = values
  const {
    inThirtyPercentOfEpicenter,
    inHalfOfEpicenter,
    inEpicenter,
    inCasualty,
    inInfluence,
  } = conditions

  let boost = 1

  if (periodDamage > 7) {
    boost = 5
  }

  if (periodDamage > 14) {
    boost = 20
  }

  let resultTime = time

  if (inCasualty || inInfluence) {
    resultTime = addMinutes(time, Math.floor(Math.random() * (12 * boost) + 12))
  }

  if (inEpicenter) {
    resultTime = addMinutes(time, Math.floor(Math.random() * (6 * boost) + 6))
  }

  if (inHalfOfEpicenter) {
    resultTime = addMinutes(time, Math.floor(Math.random() * (3 * boost) + 3))
  }

  if (inThirtyPercentOfEpicenter) {
    resultTime = addMinutes(time, Math.random() * (2 * boost) + 2)
  }

  return isBefore(resultTime, period) ? resultTime : period
}

export function calculateSimulation(radiusInfo, values, maxValues, conditions) {
  const { epicenter, casualty, influence } = radiusInfo
  const { deaths, victim, radius, time, initialTime } = values
  const { maxRadius, period, periodDamage } = maxValues
  const {
    startDeaths,
    epicenterDeaths,
    casualtyDeaths,
    influenceDeaths,
    epicenterVictim,
    casualtyVictim,
    influenceVictim,
  } = conditions

  const inThirtyPercentOfEpicenter = radius > 0 && radius <= epicenter * 0.3
  const inHalfOfEpicenter = radius > 0 && radius <= epicenter / 2
  const inEpicenter = radius > 0 && radius <= epicenter
  const inCasualty = radius > epicenter && radius <= casualty
  const inInfluence = radius > casualty && radius <= influence

  const isDeathCalculationStarted =
    differenceInSeconds(time, initialTime) >= startDeaths

  const newDeaths = isDeathCalculationStarted
    ? calculateDeathsAndVictims(
        { inEpicenter, inCasualty, inInfluence },
        [deaths],
        [epicenterDeaths, casualtyDeaths, influenceDeaths]
      )
    : 0

  const newVictims = calculateDeathsAndVictims(
    { inEpicenter, inCasualty, inInfluence },
    [victim],
    [epicenterVictim, casualtyVictim, influenceVictim]
  )

  const newTime = calculateTime(
    {
      time,
      period,
      periodDamage,
    },
    {
      inThirtyPercentOfEpicenter,
      inHalfOfEpicenter,
      inEpicenter,
      inCasualty,
      inInfluence,
    }
  )

  const newRadius = increment(radius, maxRadius)

  return {
    newDeaths,
    newVictims,
    newTime,
    newRadius,
  }
}
