import React, { useCallback, useEffect } from "react"
import _ from "lodash"

import { ModalContainer } from "./modal.styled"
import { AdaptiveContainer, FullWidth, TopRight } from "../styled"
import { useClickTransition } from "../../hooks"
import { IconButton } from "../icon-button"
import { DURATION } from "../../constants"

export function Modal({
  onClose,
  children,
  withAutoClosing,
  fixed,
  autoHeight,
}) {
  const { onClick, isMounted, isShown } = useClickTransition(true)

  const handleClose = useCallback(() => {
    onClick()
  }, [onClick])

  useEffect(() => {
    if (isMounted || isShown) {
      return
    }
    const animationTimer = setTimeout(() => {
      onClose()
    }, DURATION / 2)

    return () => {
      clearTimeout(animationTimer)
    }
  }, [isMounted, isShown, onClose])

  const handleEscape = useCallback(
    e => {
      if (e.code === "Escape") {
        handleClose()
      }
    },
    [handleClose]
  )

  useEffect(() => {
    window.addEventListener("keydown", handleEscape)

    return () => window.removeEventListener("keydown", handleEscape)
  }, [handleEscape])

  return (
    <FullWidth
      isShown={isMounted && isShown}
      isModal
      css={`
        z-index: 4;
      `}
      onClick={withAutoClosing ? handleClose : _.noop}
    >
      <ModalContainer fixed={fixed} autoHeight={autoHeight}>
        <AdaptiveContainer>{children}</AdaptiveContainer>
      </ModalContainer>
      <TopRight>
        <IconButton iconName="close" onClick={handleClose} />
      </TopRight>
    </FullWidth>
  )
}
