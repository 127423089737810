import { useCallback, useEffect, useLayoutEffect, useState } from "react"

import { DURATION } from "../constants"

export function useTransition(initialDelay) {
  const [isShown, setShown] = useState(false)

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setShown(true)
    }, initialDelay || DURATION)

    return () => {
      clearTimeout(animationTimer)
    }
  }, [initialDelay])

  return isShown
}

export function useClickTransition(auto = false) {
  const [isMounted, setMounted] = useState(auto)
  const [isShown, setShown] = useState(false)

  const onClick = useCallback(() => {
    setMounted(!isMounted)
  }, [isMounted])

  useLayoutEffect(() => {
    const animationTimer = setTimeout(() => {
      setShown(isMounted)
    }, DURATION / 2)

    return () => {
      clearTimeout(animationTimer)
    }
  }, [isMounted])

  return {
    isShown,
    isMounted,
    onClick,
  }
}
