import { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"

import { externalAddress, localAddress } from "../constants"
import { applySettings, setCountry, setDisaster } from "../data/actions"
import { getLoadIdByFeatureName, makeFeatureData } from "../utils"

export function useSubmit() {
  const dispatch = useDispatch()

  const isLocal = useSelector(({ address }) => address)
  const apiAddress = isLocal ? localAddress : externalAddress

  const [disasters, setDisasters] = useState(null)
  const [isDisastersLoaded, setDisastersLoaded] = useState(false)

  const {
    name,
    feature,
    power,
    duration,
    scale,
    country,
    region,
    disaster,
    material,
    spaceObject,
    virus,
    period,
    cont,
    daypart,
    atmospheric,
    windSpeed,
    airTemperature,
    masks,
    weight,
  } = useSelector(({ settings }) => settings)

  const [levels, setLevels] = useState(null)
  const [durations, setDurations] = useState(null)
  const [scales, setScales] = useState(null)
  const [countries, setCountries] = useState(null)
  const [regions, setRegions] = useState(null)

  const [features, setFeatures] = useState(null)
  const [disasterType, setDisasterType] = useState(null)

  // Небесное тело
  const [spaceObjects, setSpaceObjects] = useState(null)
  const [materials, setMaterials] = useState(null)

  // Вирус
  const [symptoms, setSymptoms] = useState(null)

  // Техногенная катастрофа
  const [dayparts, setDayparts] = useState(null)
  const [atmospherics, setAtmospherics] = useState(null)

  const [isLoaded, setLoaded] = useState(false)

  const handleSubmit = useCallback(() => {
    setLoaded(false)
    dispatch(
      applySettings(
        {
          disaster: disaster.id,
          duration: duration.value,
          ...makeFeatureData(name.name.en, {
            value: feature?.value || null,
            symptoms,
            virus,
            period,
            cont,
            spaceObject,
            material,
            daypart,
            atmospheric,
            windSpeed,
            airTemperature,
            masks,
            weight,
          }),
          scale: scale.value,
          location: country.value,
          region: region ? region.value : null,
        },
        null,
        apiAddress
      )
    ).then(() => {
      setLoaded(true)
      navigate("/start")
    })
  }, [
    dispatch,
    name,
    disaster,
    feature,
    duration,
    scale,
    country,
    region,
    virus,
    symptoms,
    material,
    spaceObject,
    daypart,
    atmospheric,
    windSpeed,
    airTemperature,
    masks,
    weight,
    period,
    cont,
    apiAddress,
  ])

  const loadData = useCallback(
    (api, handler) => async () => {
      setLoaded(false)
      return axios.get(`${apiAddress}/${api}`).then(({ data }) => {
        setLoaded(true)
        const sorted = _.sortBy(data, "id")
        handler(sorted)
        return sorted
      })
    },
    [apiAddress]
  )

  useEffect(() => {
    if (!name || !power) {
      return
    }
    const disaster = _.find(disasters, {
      disasterType: {
        name_en: name.name.en,
      },
      lvl: {
        id: power.value,
      },
    })
    dispatch(setDisaster(disaster))
  }, [dispatch, disasters, name, power])

  const handleDispatch = useCallback(
    action => value => () => dispatch(action(value)),
    [dispatch]
  )

  const selectAllSymptoms = useCallback(() => {
    const symptomsId = feature.symptoms.map(({ id }) => id)
    setSymptoms(symptomsId)
  }, [feature])

  useEffect(() => {
    if (!name || name.name.en !== "Virus" || !feature) {
      return
    }

    selectAllSymptoms()
  }, [feature, name, selectAllSymptoms])

  const selectSymptoms = useCallback(
    id => () => {
      const symptomsCopy = [...symptoms]
      const symptomIndex = symptoms.findIndex(symptomId => symptomId === id)

      if (symptomIndex !== -1) {
        symptomsCopy.splice(symptomIndex, 1)
      } else {
        symptomsCopy.push(id)
      }

      if (symptomsCopy.length < 1) {
        return
      }

      setSymptoms(symptomsCopy)
    },
    [symptoms]
  )

  const handleLoad = useCallback(
    loadId => {
      switch (loadId) {
        case "feature":
          return loadData(getLoadIdByFeatureName(name.name.en), setFeatures)

        // Небесное тело
        case "spaceObject":
          return loadData("spaceobject", setSpaceObjects)()
        case "material":
          return loadData("material", setMaterials)

        // Техногенная катастрофа
        case "daypart":
          return loadData("daypart", setDayparts)
        case "atmospheric":
          return loadData("atmospheric", setAtmospherics)
        case "disasterType":
          return loadData("disastertype", setDisasterType)

        // Общие свойства
        case "lvl":
          return loadData("lvl", setLevels)
        case "duration":
          return loadData("duration", setDurations)
        case "scale":
          return loadData("scale", setScales)
        case "location":
          return loadData("countries", setCountries)()
        case "region":
          return loadData(`regions/${country.value}/1`, setRegions)()
        default:
          break
      }
    },
    [name, country, loadData]
  )

  const handleClickWorldMap = useCallback(
    isoCode => () => {
      if (!countries) {
        return
      }

      const selectedCountry = _.find(countries, { code: isoCode })

      if (!selectedCountry) {
        return
      }

      const {
        id,
        name_en,
        name_ru,
        code,
        population,
        latitude,
        longitude,
        density,
      } = selectedCountry

      handleDispatch(setCountry)({
        value: id,
        name: {
          en: name_en,
          ru: name_ru,
        },
        code,
        population,
        latitude,
        longitude,
        density,
      })()
    },
    [countries, handleDispatch]
  )

  useEffect(() => {
    setLoaded(false)
    axios.get(`${apiAddress}/disaster`).then(({ data }) => {
      setDisastersLoaded(true)
      setLoaded(true)
      setDisasters(data)
      handleLoad("disasterType")()
    })
    // eslint-disable-next-line
  }, [apiAddress])

  useEffect(() => {
    if (countries) {
      return
    }
    handleLoad("location")
    // eslint-disable-next-line
  }, [countries])

  useEffect(() => {
    if (!country) {
      return
    }
    handleLoad("region")
    // eslint-disable-next-line
  }, [country])

  useEffect(() => {
    if (!name || name.name.en !== "Celestial body" || !feature) {
      return
    }
    const { value } = feature

    handleLoad("spaceObject").then(data => {
      const selectedSpaceObjects = _.filter(data, {
        typeSpaceObject: { id: value },
      })
      setSpaceObjects(selectedSpaceObjects)
    })
    // eslint-disable-next-line
  }, [name, feature])

  return {
    isLoaded,
    isDisastersLoaded,
    handleDispatch,
    handleSubmit,
    handleLoad,
    handleClickWorldMap,
    symptoms,
    selectSymptoms,
    selectAllSymptoms,
    receivedData: {
      disasterType,
      spaceObjects,
      dayparts,
      atmospherics,
      levels,
      durations,
      scales,
      countries,
      regions,
      features,
      materials,
    },
  }
}
