import React, { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { CgFormatSlash } from "react-icons/cg"

import { FooterButton, FooterGroup, FooterPanel } from "./footer.styled"
import { FormattedText, LanguageIcon, TextButton } from "./text.styled"
import { Text } from "../styled"
import { useLanguage, useWindowSize } from "../../hooks"
import { Switch } from "../switch"
import { FooterAboutModal } from "./footer-about-modal"
import { CheckboxLocal } from "./checkbox-local"
import { setDefaultValues } from "../../data/actions"

export const Footer = React.memo(({ status }) => {
  const dispatch = useDispatch()
  const { t, lang, setLanguage } = useLanguage()
  const isLocal = useSelector(({ address }) => address)
  const { isMobile } = useWindowSize()

  const [isModalShown, setModalShown] = useState(false)
  const [isLocalCheckboxShown, setLocalCheckboxShown] = useState(false)

  const handleModal = useCallback(() => {
    setModalShown(!isModalShown)
  }, [isModalShown])

  const handleEnableLocal = useCallback(
    e => {
      if (e.altKey && e.code === "KeyL") {
        setLocalCheckboxShown(!isLocalCheckboxShown)
      }

      if (e.altKey && e.code === "KeyD") {
        dispatch(setDefaultValues())
      }
    },
    [isLocalCheckboxShown, dispatch]
  )

  useEffect(() => {
    window.addEventListener("keydown", handleEnableLocal)
    return () => window.removeEventListener("keydown", handleEnableLocal)
  }, [handleEnableLocal])

  return (
    <FooterPanel>
      {!isMobile && (
        <FooterGroup>
          <Text>{`${t("status.title")}: `}</Text>
          <FormattedText>{status}</FormattedText>
        </FooterGroup>
      )}

      <FooterGroup>
        {!isMobile && (
          <>
            <LanguageIcon fontSize={16} />
            <Text>{`${t("footer.language")}: `}</Text>
          </>
        )}
        <TextButton onClick={setLanguage("ru")} isEnabled={lang === "ru"}>
          {t("footer.languages.ru")}
        </TextButton>
        <CgFormatSlash fontSize={20} />
        <TextButton onClick={setLanguage("en")} isEnabled={lang === "en"}>
          {t("footer.languages.en")}
        </TextButton>
      </FooterGroup>

      <FooterGroup>
        <Switch />
      </FooterGroup>
      {(isLocalCheckboxShown || isLocal) && (
        <FooterGroup>
          <Text
            css={`
              padding-right: 5px;
            `}
          >
            {`${t("footer.local")}: `}
          </Text>
          <CheckboxLocal />
        </FooterGroup>
      )}

      <FooterGroup>
        <FooterButton onClick={handleModal}>
          {t("footer.about.title")}
        </FooterButton>
      </FooterGroup>
      {isModalShown && <FooterAboutModal onClose={handleModal} />}
    </FooterPanel>
  )
})

Footer.displayName = "Footer"
