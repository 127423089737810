import React, { useEffect } from "react"
import { Reset } from "styled-reset"
import { ThemeProvider } from "styled-components"

import { useTheme, useTransition } from "../../hooks"
import { FullWidth } from "../styled"
import { oldTheme } from "../../theme"
import { ToastConnectionInfo } from "../toast"

export function PageContainer({ children }) {
  const { darkThemeEnabled } = useTheme()
  const isShown = useTransition()

  useEffect(() => {
    document.body.style.backgroundColor = darkThemeEnabled
      ? oldTheme.dark
      : oldTheme.light
  }, [darkThemeEnabled])

  return (
    <ThemeProvider theme={{ theme: darkThemeEnabled ? "dark" : "light" }}>
      <FullWidth isShown={isShown}>
        <Reset />
        {children}
        <ToastConnectionInfo />
      </FullWidth>
    </ThemeProvider>
  )
}
