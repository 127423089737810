export function drawCircle([latitude, longitude], radiusInKm, points = 64) {
  let km = radiusInKm

  const ret = []
  const distanceX = km / (111.32 * Math.cos((latitude * Math.PI) / 180))
  const distanceY = km / 110.574

  let theta, x, y
  ;[...Array(points).keys()].forEach((_, idx) => {
    theta = (idx / points) * (2 * Math.PI)
    x = distanceX * Math.cos(theta)
    y = distanceY * Math.sin(theta)
    ret.push([longitude + x, latitude + y])
  })

  ret.push(ret[0])

  return {
    type: "geojson",
    data: {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [ret],
      },
    },
  }
}
