import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FixedSizeList as List } from "react-window"
import styled from "styled-components"
import _ from "lodash"

import { Modal } from "../../modal"
import {
  GiAsteroid,
  GiBurningMeteor,
  GiBurningSkull,
  GiCometSpark,
  GiDisintegrate,
  GiFactory,
  GiFastArrow,
  GiMiddleArrow,
  GiNuclearBomb,
  GiQuicksand,
  GiResize,
  GiSmallFire,
  GiTerror,
  IoIosCloudyNight,
  IoMdCloudyNight,
  MdPhotoSizeSelectSmall,
  RiEarthquakeFill,
  RiFloodFill,
  RiVirusFill,
  SiMeteor,
  TiWeatherPartlySunny,
  TiWeatherShower,
  TiWeatherSunny,
  WiDayCloudyHigh,
  WiDaySunny,
} from "react-icons/all"
import { useLanguage, useTheme } from "../../../hooks"
import { iconProps } from "../../../constants"
import { InputText, MenuButton } from "../../styled"
import { MenuItem } from "../../menu"
import { IconButton } from "../../icon-button"

const iconSet = {
  idDisaster: [
    <GiNuclearBomb {...iconProps} />,
    <RiVirusFill {...iconProps} />,
    <RiFloodFill {...iconProps} />,
    <RiEarthquakeFill {...iconProps} />,
    <GiAsteroid {...iconProps} />,
    <GiFactory {...iconProps} />,
  ],
  idLvl: [
    <GiSmallFire {...iconProps} />,
    <GiDisintegrate {...iconProps} />,
    <GiTerror {...iconProps} />,
  ],
  idDuration: [
    <GiQuicksand {...iconProps} />,
    <GiMiddleArrow {...iconProps} />,
    <GiFastArrow {...iconProps} />,
  ],
  idScale: [
    <MdPhotoSizeSelectSmall {...iconProps} />,
    <GiResize {...iconProps} />,
    <GiBurningSkull {...iconProps} />,
  ],
  idAsteroid: [
    <GiCometSpark {...iconProps} />,
    <GiBurningMeteor {...iconProps} />,
    <SiMeteor {...iconProps} />,
  ],
  idDaypart: [
    <IoIosCloudyNight {...iconProps} />,
    <WiDayCloudyHigh {...iconProps} />,
    <WiDaySunny {...iconProps} />,
    <IoMdCloudyNight {...iconProps} />,
  ],
  idAtmospheric: [
    <TiWeatherSunny {...iconProps} />,
    <TiWeatherPartlySunny {...iconProps} />,
    <TiWeatherShower {...iconProps} />,
  ],
}

const RowHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ w }) => (w ? `${w}px` : "auto")};
`

export function SettingsModal({ data, id, onClick, onClose, noDescription }) {
  const { t, lang } = useLanguage()
  const { darkThemeEnabled } = useTheme()
  const [dimensions, setDimensions] = useState({ h: 0, w: 0 })
  const [filter, setFilter] = useState("")
  const [sorting, setSorting] = useState("asc")

  useEffect(() => {
    const handleResize = () => {
      const { innerHeight: height, innerWidth: width } = window
      setDimensions({
        h: height * 0.7,
        w: width > 600 ? 600 : width > 400 ? 400 : 200,
      })
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handlePreventDefault = useCallback(e => e.stopPropagation(), [])

  const list = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(item => ({
      name: item[`name_${lang}`],
      needWorkName: item.name_en,
      description: item[`description_${lang}`],
      id: item.id,
      icon: iconSet[id] && iconSet[id][item.id - 1],
      charge: item.charge,
      onClick: onClick({
        value: item.id,
        name: {
          en: item.name_en,
          ru: item.name_ru,
        },
        description: {
          en: item.description_en,
          ru: item.description_ru,
        },
        code: item.code,
        url: item.url,
        symptoms: item.symptoms,
        class: {
          en: item.class_en,
          ru: item.class_ru,
        },
        population: item.population,
        density: item.density,
        charge: item.charge,
        weight: item.weight,
        length: item.length,
        diameter: item.diameter,
        latitude: item.latitude,
        longitude: item.longitude,
        scale: item.population,
        budget: item.density,
      }),
    }))
  }, [data, id, onClick, lang])

  useEffect(() => {
    if (!!list && list.length <= 10) {
      return
    }
    setSorting("asc")
  }, [list])

  const handleClickSort = useCallback(
    value => e => {
      handlePreventDefault(e)
      setSorting(value)
    },
    [handlePreventDefault]
  )

  const handleChange = useCallback(e => {
    const value = e.target.value
    setFilter(value)
  }, [])

  const sortedList = useMemo(() => {
    if (Boolean(list[0]?.charge)) {
      return _.orderBy(list, ["charge"], [sorting])
    }
    return _.orderBy(list, [item => item.name.toLowerCase()], [sorting])
  }, [list, sorting])

  const filteredList = useMemo(
    () =>
      _.filter(
        sortedList,
        item => item.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
      ),
    [sortedList, filter]
  )

  const Row = ({ index, style }) => (
    <MenuButton
      style={style}
      onClick={filteredList[index].onClick}
      noTransform
      noDescription
    >
      <MenuItem
        title={
          Boolean(filteredList[index].charge)
            ? `${filteredList[index].name} (${t(
                "settings.disaster.Atomic bomb.measure",
                {
                  charge: filteredList[index].charge,
                }
              )})`
            : filteredList[index].name
        }
        noTransform
        isOdd={index % 2}
      />
    </MenuButton>
  )

  // eslint-disable-next-line
  const omitDisasters = name => {
    //   return name === "Asteroid" || name === "Man-made disaster"
    return false
  }

  return (
    <Modal onClose={onClose} fixed={!!list && list.length > 10} withAutoClosing>
      {!!list && list.length <= 10 ? (
        list.map(({ name, needWorkName, description, id, icon, onClick }) => (
          <MenuButton
            key={id}
            onClick={omitDisasters(needWorkName) ? _.noop : onClick}
          >
            <MenuItem
              title={`${name}${
                omitDisasters(needWorkName) ? ` (${t("feature.inWork")})` : ""
              }`}
              description={description}
              icon={icon}
              descriptionOnHover={list.length >= 4 || noDescription}
              noDescription={noDescription}
            />
          </MenuButton>
        ))
      ) : (
        <>
          <RowHeader w={dimensions.w}>
            <InputText
              noTransform
              noShadow
              onClick={handlePreventDefault}
              placeholder={t("settings.search")}
              value={filter}
              onChange={handleChange}
            />
            <RowHeader>
              <IconButton
                iconName={"sortAsc"}
                onClick={handleClickSort("asc")}
              />
              <IconButton
                iconName={"sortDesc"}
                onClick={handleClickSort("desc")}
              />
            </RowHeader>
          </RowHeader>
          <List
            height={dimensions.h}
            width={dimensions.w}
            itemCount={filteredList.length}
            itemSize={60}
            css={`
              &::-webkit-scrollbar {
                width: 10px;
              }

              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 8px;
              }

              &::-webkit-scrollbar-thumb {
                background-color: ${darkThemeEnabled ? "#c4c4c4" : "#6b6b6b"};
                border-radius: 6px;
              }
            `}
          >
            {Row}
          </List>
        </>
      )}
    </Modal>
  )
}
