import styled from "styled-components"
import { baseShadow } from "../../theme"

export const Img = styled.img`
  width: 600px;
  height: 400px;
  object-fit: cover;
  margin: 10px auto;
  box-shadow: ${baseShadow};
  border-radius: 8px;
`
