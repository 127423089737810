import React from "react"

import { Modal } from "../modal"
import { Text, Title } from "../styled"
import { useLanguage } from "../../hooks"
import { AboutContainer } from "./footer-about-modal.styled"
import { FormattedText } from "./text.styled"

export function FooterAboutModal({ onClose }) {
  const { t } = useLanguage()

  return (
    <Modal onClose={onClose} fixed={true} autoHeight>
      <AboutContainer>
        <Title>Emergency Analyzer</Title>
        <Text notMobile>{t("footer.about.description")}</Text>
        <div
          css={`
            margin-top: 20px;
          `}
        >
          <Text>{`${t("footer.about.version")}: `}</Text>
          <FormattedText>{process.env.REACT_APP_VERSION}</FormattedText>
        </div>
      </AboutContainer>
    </Modal>
  )
}
