import React, { useCallback } from "react"
import { navigate } from "gatsby"

import { Container } from "../styled"
import { IconButton } from "../icon-button"
import { HorizontalContent, Menu } from "./menu.styled"

export function TopMenu({ children, fixed = false }) {
  const handleGoBack = useCallback(() => {
    navigate("/")
  }, [])
  return (
    <Menu fixed={fixed}>
      <HorizontalContent>
        <Container>
          <IconButton onClick={handleGoBack} iconName="back" />
        </Container>
        <Container>
          <HorizontalContent>{children}</HorizontalContent>
        </Container>
      </HorizontalContent>
    </Menu>
  )
}
