import React, { useCallback, useState } from "react"
import styled from "styled-components"

import { MenuButton } from "../../styled"
import { MenuItem } from "../../menu"
import { SettingsModal } from "../modals"

const SettingsContainer = styled.div`
  width: 400px;

  @media (max-width: 520px) {
    width: 300px;
  }
`

export function SettingsMenuItem({
  title,
  description,
  data,
  id,
  onClick,
  onLoad,
  icon,
  noDescription,
  notFilled,
}) {
  const [isModalShown, setModalShown] = useState(false)

  const handleModal = useCallback(() => {
    const newValue = !isModalShown
    setModalShown(newValue)
    if (newValue) {
      onLoad()
    }
  }, [isModalShown, onLoad])

  return (
    <SettingsContainer>
      <MenuButton onClick={handleModal}>
        <MenuItem
          title={title}
          description={description}
          icon={icon}
          warning={notFilled}
        />
      </MenuButton>
      {isModalShown && (
        <SettingsModal
          data={data}
          id={id}
          onClick={onClick}
          onClose={handleModal}
          noDescription={noDescription}
        />
      )}
    </SettingsContainer>
  )
}
