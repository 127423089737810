/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react"
import debounce from "lodash.debounce"
import { MOBILE_WIDTH } from "../constants"

export function useWindowSize(container) {
  const [size, setSize] = useState({ w: 1, h: 1, isMobile: false })

  useEffect(() => {
    const handleResize = debounce(() => {
      const { innerWidth: w, innerHeight: h } = window

      if (container?.current) {
        const {
          width: containerWidth,
        } = container.current.getBoundingClientRect()
        setSize({ w: 340, h: 300, isMobile: w <= MOBILE_WIDTH })
      } else {
        setSize({ w, h, isMobile: w <= MOBILE_WIDTH })
      }
    }, 100)

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [container])

  return size
}
