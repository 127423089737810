import React, { memo, useEffect, useState } from "react"
import axios from "axios"
import { shallowEqual, useSelector } from "react-redux"
import { v4 } from "uuid"

import { useLanguage } from "../../hooks"
import { externalProxy, localAddress } from "../../constants"
import { NotificationContent, NotificationText } from "../notification"
import { Loader } from "../loader"
import { Centered } from "../styled"

export const Changelog = memo(() => {
  const { lang } = useLanguage()
  const [isLoaded, setLoaded] = useState(false)
  const isLocal = useSelector(({ address }) => address, shallowEqual)

  const [log, setLog] = useState(null)

  useEffect(() => {
    setLoaded(false)
    axios
      .get(`${isLocal ? localAddress : externalProxy}/notification_${lang}.txt`)
      .then(({ data }) => {
        setLoaded(true)
        const rowData = data.split("\n")
        const parsedData = rowData.map(text => ({
          text,
          id: v4(),
        }))
        setLog(parsedData)
      })
  }, [lang, isLocal])

  return (
    <NotificationContent>
      {Boolean(isLoaded && log) ? (
        log.map(({ text, id }) => (
          <NotificationText key={id}>{text}</NotificationText>
        ))
      ) : (
        <Centered>
          <Loader invert />
        </Centered>
      )}
    </NotificationContent>
  )
})

Changelog.displayName = "Changelog"
