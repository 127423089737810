export const DURATION = 250
export const PING_TIMEOUT = 2000
export const TOAST_OPEN_DURATION = 2000
export const TOAST_CLOSE_DURATION = 3000
export const UPDATE_INTERVAL_TIMEOUT = 5000
export const ANIMATING_TIME = 1000
export const iconProps = {
  fontSize: 26,
}
export const externalAddress = process.env.API_ADDRESS
export const externalProxy = process.env.PROXY_URL

export const localAddress = "http://localhost:8080"

export const MIN_D_WIDTH = 1030

export const SIMULATION_SPEED = 500
export const MOBILE_WIDTH = 520
