export function getLoadIdByFeatureName(name) {
  switch (name) {
    case "Atomic bomb":
      return "atomicbomb"
    case "Virus":
      return "classvirus"
    case "Celestial body":
      return "typespaceobject"
    case "Man-made disaster":
      return "chemicalsubstance"
    default:
      return "id"
  }
}

export function getIdByFeatureName(name) {
  switch (name) {
    case "Atomic bomb":
      return "idBomb"
    case "Virus":
      return "idClassVirus"
    case "Celestial body":
      return "idAsteroid"
    default:
      return "id"
  }
}
