import React from "react"
import ReactSwitch from "react-switch"

import { useTheme } from "../../hooks"
import { DarkMode, DarkModeInverted } from "../styled"
import { oldTheme } from "../../theme"

const switchTheme = {
  height: 20,
  onColor: "#919191",
  offColor: "#cbd2d3",
  offHandleColor: "#919191",
  onHandleColor: "#cbd2d3",
  activeBoxShadow: oldTheme.highlight,
}

export function Switch() {
  const { darkThemeEnabled, changeTheme } = useTheme()

  return (
    <ReactSwitch
      {...switchTheme}
      checked={darkThemeEnabled}
      onChange={changeTheme}
      uncheckedIcon={<DarkMode fontSize={20} />}
      checkedIcon={<DarkModeInverted fontSize={20} />}
    />
  )
}
