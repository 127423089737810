import { useEffect, useState } from "react"
import {
  generateInfo,
  generateLog,
  generateRandomCountryNumber,
} from "../utils"
import _ from "lodash"
import {
  ANIMATING_TIME,
  externalAddress,
  localAddress,
  UPDATE_INTERVAL_TIMEOUT,
} from "../constants"
import { useDispatch, useSelector } from "react-redux"
import { logRequest } from "../data/actions"

export function useRandomInfo() {
  const isLocal = useSelector(({ address }) => address)
  const [isLogLoaded, setLogLoaded] = useState(false)
  const [randomInfo, setRandomInfo] = useState([])
  const [isLoaded, setLoaded] = useState(false)
  const [randomLog, setRandomLog] = useState([])

  const dispatch = useDispatch()
  const logs = useSelector(({ log }) => log)

  useEffect(() => {
    setLogLoaded(false)
    const apiAddress = isLocal ? localAddress : externalAddress

    dispatch(logRequest(apiAddress)).then(() => {
      setLogLoaded(true)
    })
  }, [dispatch, isLocal])

  useEffect(() => {
    if (!isLogLoaded || _.isEmpty(logs)) {
      return
    }
    let loadedTimer = 0
    let generatedTimer = 0
    let animationTimer = 0

    const interval = setInterval(() => {
      setLoaded(false)
      animationTimer = setTimeout(() => {
        const randomCountriesNumber = generateRandomCountryNumber()
        const info = generateInfo(randomCountriesNumber, logs)
        const log = generateLog(info)
        setRandomInfo(info)
        setRandomLog(log)

        setLoaded(true)
      }, ANIMATING_TIME)
    }, UPDATE_INTERVAL_TIMEOUT)

    return () => {
      clearInterval(interval)
      clearTimeout(loadedTimer)
      clearTimeout(generatedTimer)
      clearTimeout(animationTimer)
    }
  }, [isLogLoaded, logs])

  return {
    info: randomInfo,
    log: randomLog,
    isLoaded,
  }
}
