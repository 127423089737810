import React from "react"

import { Container, FlexColumn } from "../styled"
import { Menu } from "./menu.styled"

export function MiddleMenu({ children }) {
  return (
    <Menu isMiddle>
      <Container>
        <FlexColumn>{children}</FlexColumn>
      </Container>
    </Menu>
  )
}
