import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"

import {
  fgColor,
  fgColorSecondary,
  hover,
  oldTheme,
  warningColor,
} from "../../theme"

export const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: ${({ autoWidth }) => (autoWidth ? "auto" : "100%")};
  height: 100%;
  padding: 10px;
  border-radius: 8px;
  color: ${({ customColor }) => (customColor ? "inherit" : fgColor)};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${fgColor};
    `};

  ${({ needToPress }) =>
    needToPress &&
    css`
      color: ${warningColor};
    `};

  ${({ active }) =>
    active &&
    css`
      background-color: ${fgColor};
      color: ${hover};
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:focus {
    outline: none;
    background-color: ${fgColor};
    color: ${hover};
  }

  &:active {
    outline: none;
  }

  &:hover {
    background-color: ${fgColor};
    color: ${hover};
  }

  @media (max-width: 600px) {
    ${({ active }) =>
      active &&
      css`
        border-bottom-right-radius: 6px;
        border-top-left-radius: 0;
      `}
  }
`

export const Link = styled(GatsbyLink)`
  display: block;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  font-size: 18px;
  border-radius: 6px;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      box-shadow: 0 0 0 4px ${warningColor};
      animation: signal 2s linear infinite;

      @keyframes signal {
        0% {
          box-shadow: 0 0 0 0 ${warningColor};
        }
        50% {
          box-shadow: 0 0 0 4px ${warningColor};
        }
        100% {
          box-shadow: 0 0 0 0 ${warningColor};
        }
      }
    `}

  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1, 1.2);
    background-color: ${hover};
  }

  &:focus {
    outline: none;
    transform: scale(1.1, 1.2);
    background-color: ${hover};
  }

  &:active {
    outline: none;
  }
`

export const TextLink = styled.a`
  display: inline;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  vertical-align: center;
  font-size: 14px;

  cursor: pointer;
  color: ${fgColorSecondary};
  background-color: transparent;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-bottom: 2px solid ${oldTheme.warningColor};
  }

  &:active,
  &:focus {
    outline: none;
  }
`

export const MenuButton = styled(Button)`
  z-index: 0;
  text-align: left;
  padding: 0;

  transition: transform 0.3s ease-in-out;

  &:hover {
    background-color: unset;
    color: unset;
    transform: ${({ noTransform }) =>
      noTransform ? "unset" : "scale(1.1, 1.2)"};
  }

  &:focus {
    outline: none;
    transform: ${({ noTransform }) =>
      noTransform ? "unset" : "scale(1.1, 1.2)"};
    background-color: unset;
    color: unset;
  }
`
