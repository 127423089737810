import TopBarProgress from "react-topbar-progress-indicator"
import React from "react"

TopBarProgress.config({
  barColors: {
    0: "#e3a09e",
    1: "#e3a09e",
  },
  barThickness: 10,
  shadowBlur: 5,
})

export function TopBar() {
  return <TopBarProgress />
}
