import React, { useMemo } from "react"
import _ from "lodash"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { FlexCentered, Img, TextSecondary, Title } from "../../styled"
import { Description } from "../../description"
import { useLanguage } from "../../../hooks"
import { fgColorSecondary } from "../../../theme"
import { Checkbox } from "../../checkbox"
import { TextButton } from "../../footer/text.styled"
import { Symptom } from "../symptom"

const LiText = styled(TextSecondary)`
  font-size: 16px;
`

const ListItem = styled.li`
  position: relative;
  padding-left: 52px;
  counter-increment: customlistcounter;

  &::before {
    position: absolute;
    left: 25px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    color: ${fgColorSecondary};
    width: 26px;
    height: 20px;
    font-size: 16px;
    line-height: 1.18;
    content: counter(customlistcounter) ".";
  }

  &::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: ${fgColorSecondary};
    content: "";
  }

  & + & {
    margin-top: 20px;
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
`

const OrderedList = styled.ol`
  margin-top: 10px;
  list-style: none;
`

export function DescriptionFeature({
  symptoms,
  selectSymptoms,
  selectAllSymptoms,
}) {
  const { t, lang } = useLanguage()
  const { feature, spaceObject } = useSelector(({ settings }) => settings)

  const spaceObjectUrl = useMemo(() => {
    if (!spaceObject) {
      return null
    }

    const { url } = spaceObject
    if (url === "null") {
      return null
    }

    return url
  }, [spaceObject])

  return (
    <Description>
      <Title fontSize={24}>{`${feature.name[lang]}${
        feature.class[lang] ? ` - ${feature.class[lang]}` : ""
      }${spaceObject ? ` ${spaceObject.name[lang]}` : ""}`}</Title>

      <Img src={spaceObjectUrl || feature.url} alt={feature.name[lang]} />

      <TextSecondary fontSize={18} lineHeight={1.3}>
        {feature.description[lang]}
      </TextSecondary>

      {spaceObject && (
        <TextSecondary fontSize={18} lineHeight={1.3}>
          {spaceObject.description[lang]}
        </TextSecondary>
      )}

      {!_.isEmpty(feature.symptoms) && (
        <>
          <FlexCentered>
            <TextSecondary fontSize={18}>
              {t("settings.disaster.symptoms")}:
            </TextSecondary>
            <TextButton isEnabled={false} onClick={selectAllSymptoms}>
              {t("settings.disaster.selectAllSymptoms")}
            </TextButton>
          </FlexCentered>

          <OrderedList>
            {symptoms &&
              feature.symptoms.map(symptom => (
                <ListItem key={symptom.id}>
                  <Checkbox
                    checked={symptoms.find(id => id === symptom.id)}
                    s
                    onChange={selectSymptoms(symptom.id)}
                    insideList
                  />
                  <LiText>
                    <Symptom
                      title={symptom[`name_${lang}`]}
                      description={symptom[`description_${lang}`]}
                    />
                  </LiText>
                </ListItem>
              ))}
          </OrderedList>
        </>
      )}
    </Description>
  )
}
