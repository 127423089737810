import mapboxgl from "mapbox-gl"

import { useTheme } from "./use-theme"
import { useLanguage } from "./use-language"
import { useEffect, useRef, useState } from "react"
import { DURATION } from "../constants"

function defineStyle(isDark) {
  return `mapbox://styles/mapbox/${isDark ? "dark" : "light"}-v10`
}

export function useMap({ setLoaded }) {
  const { darkThemeEnabled } = useTheme()
  const { lang } = useLanguage()

  const mapRef = useRef()
  const [mapProps] = useState({
    lng: 5,
    lat: 34,
    zoom: 3,
  })
  const [mapObject, setMapObject] = useState(null)

  useEffect(() => {
    if (!mapRef.current) {
      return
    }
    const { lng, lat, zoom } = mapProps

    mapboxgl.accessToken = process.env.API_TOKEN

    const map = new mapboxgl.Map({
      container: mapRef.current,
      style: defineStyle(darkThemeEnabled),
      center: [lng, lat],
      zoom,
    })

    setMapObject(map)
    const mapBoxControls = [
      ...document.getElementsByClassName("mapboxgl-control-container"),
    ]
    mapBoxControls.forEach(element =>
      element.parentElement.removeChild(element)
    )
    // eslint-disable-next-line
  }, [mapProps, mapRef])

  useEffect(() => {
    if (!mapObject) {
      return
    }

    setLoaded(false)
    mapObject.setStyle(defineStyle(darkThemeEnabled))
    const languageInterval = setInterval(() => {
      if (!mapObject.isStyleLoaded()) {
        return
      }
      mapObject.setLayoutProperty("country-label", "text-field", [
        "get",
        `name_${lang}`,
      ])

      setLoaded(true)
      clearInterval(languageInterval)
    }, DURATION)

    return () => {
      clearTimeout(languageInterval)
    }
  }, [mapObject, darkThemeEnabled, lang, setLoaded])

  return {
    map: mapRef,
    mapObject,
  }
}
