import { useCallback } from "react"
import { shallowEqual, useSelector } from "react-redux"

import { checkFeatureExists } from "../utils"

export function useFeature() {
  const {
    name,
    feature,
    virus,
    spaceObject,
    material,
    daypart,
    atmospheric,
  } = useSelector(({ settings }) => settings, shallowEqual)

  const checkFeatureFilled = useCallback(() => {
    if (!feature) {
      return !checkFeatureExists(name)
    }

    switch (name.name.en) {
      case "Atomic bomb":
        return true
      case "Virus":
        return Boolean(virus)
      case "Celestial body":
        if (feature.name.en === "Meteorite") {
          return Boolean(material)
        }
        return Boolean(spaceObject && material)
      case "Man-made disaster":
        return Boolean(daypart && atmospheric)
      default:
        return false
    }
  }, [name, feature, virus, spaceObject, material, daypart, atmospheric])

  return {
    checkFeatureFilled,
  }
}
