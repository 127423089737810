import styled, { css } from "styled-components"

import { bgColor, fgColor, fgColorSecondary, warningColor } from "../../theme"

const hideWhenMobile = notMobile =>
  notMobile &&
  css`
    @media (max-width: 520px) {
      display: none;
    }
  `

export const Text = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: ${({ inverted }) => (inverted ? bgColor : fgColor)};

  transition: color 0.2s ease-in-out;

  ${({ notMobile }) => hideWhenMobile(notMobile)}
`

export const TextSecondary = styled(Text)`
  padding: 5px 0;
  color: ${({ warning }) => (warning ? warningColor : fgColorSecondary)};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "16px")};
  line-height: ${({ lineHeight }) => lineHeight || 1};

  transition: color 0.2s ease-in-out;

  ${({ notMobile }) => hideWhenMobile(notMobile)}
`

export const Title = styled.h1`
  text-align: center;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  color: ${fgColor};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "32px")};

  transition: color 0.2s ease-in-out;

  @media (max-width: 520px) {
    font-size: 20px;
  }
`
