import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import {
  VscDebugStart,
  VscReferences,
  VscReport,
  VscSettingsGear,
} from "react-icons/vsc"
import { useTranslation } from "react-i18next"

import { Centered, Container, Link, Title } from "../styled"
import { MenuItem } from "./menu-item"
import { initialDamage } from "../../data/reducers/damage"
import { Notification } from "../notification"
import { Changelog } from "../changelog"

const iconProps = {
  fontSize: "26px",
}

const menuItems = [
  {
    route: "start",
    icon: <VscDebugStart {...iconProps} />,
  },
  {
    route: "settings",
    icon: <VscSettingsGear {...iconProps} />,
  },
  {
    route: "references",
    icon: <VscReferences {...iconProps} />,
  },
  {
    route: "reports",
    icon: <VscReport {...iconProps} />,
  },
]

const disabledCss = `cursor: default; pointer-events: none;`

export function MainMenu() {
  const { t } = useTranslation()
  const damage = useSelector(({ damage }) => damage)

  return (
    <Centered
      css={`
        @media (max-width: 520px) {
          top: 55%;
        }
      `}
    >
      <Container>
        <Notification mode="notification">
          <Changelog />
        </Notification>
        <Title
          css={`
            padding: 20px 40px 0;
          `}
        >
          Emergency Analyzer
        </Title>
        {menuItems.map(({ route, icon }, idx) => (
          <Link
            to={`/${route}`}
            key={route}
            disabled={!Boolean(idx) && _.isEqual(damage, initialDamage)}
            isHighlighted={idx === 1 && _.isEqual(damage, initialDamage)}
            css={
              !Boolean(idx) && _.isEqual(damage, initialDamage)
                ? disabledCss
                : ``
            }
          >
            <MenuItem
              title={t(`menu.${route}`)}
              description={t(`menu.description.${route}`)}
              icon={icon}
            />
          </Link>
        ))}
      </Container>
    </Centered>
  )
}
