import styled from "styled-components"
import { DURATION } from "../../constants"

export const DescriptionContainer = styled.section`
  padding: 30px 0 30px;
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  transition: opacity ${DURATION}ms ease-in-out;

  & + & {
    margin-left: 30px;
  }

  &:last-child {
    padding-right: 30px;
  }
`
