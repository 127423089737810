import styled from "styled-components"
import { FlexCentered, Img } from "../styled"

export const AboutContainer = styled.div`
  padding: 10px 20px;
  text-align: center;

  @media (max-width: 520px) {
    padding: 10px;
  }
`

export const CircledImg = styled(Img)`
  height: 64px;
  width: 64px;
  border-radius: 25em;
  object-fit: cover;
  margin: unset;
`

export const CenteredItems = styled(FlexCentered)`
  margin: 0 auto;
  width: 60%;

  & + & {
    margin-top: 20px;
  }

  @media (max-width: 520px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
