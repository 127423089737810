import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import debounce from "lodash.debounce"

import { IconButton } from "../icon-button"
import { bgColor, fgColor, warningColor } from "../../theme"
import { useClickTransition } from "../../hooks"

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ mode }) =>
    mode === "error" || "warning" ? warningColor : fgColor};
`

const NotificationContainer = styled.div`
  z-index: 3;
  position: absolute;
  right: 1px;
  top: 0;
  width: 250px;
  height: ${({ height }) => `${height}px`};
  background-color: ${fgColor};
  color: ${bgColor};
  transform: translateX(100%);
  border-radius: 0 6px 6px 6px;

  opacity: ${({ isShown }) => Number(isShown)};

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;

  @media (max-width: 600px) {
    right: 0;
    top: 1px;
    border-radius: 6px 6px 0 6px;
    transform: translate(0, -100%);
  }
`

const DEFAULT_HEIGHT = 250

export function Notification({ children, mode, iconSize, skipHeightCheck }) {
  const pointerRef = useRef(null)
  const { onClick, isMounted, isShown } = useClickTransition()
  const [containerHeight, setContainerHeight] = useState(0)

  useEffect(() => {
    if (skipHeightCheck) {
      return
    }

    const handleResize = debounce(() => {
      if (!pointerRef.current) {
        return
      }

      const { top } = pointerRef.current.getBoundingClientRect()
      const newHeight = top <= DEFAULT_HEIGHT ? top - 10 : DEFAULT_HEIGHT
      setContainerHeight(newHeight)
    }, 100)

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [skipHeightCheck])

  return (
    <ButtonContainer mode={mode} ref={pointerRef}>
      <IconButton
        iconName={mode}
        iconSize={iconSize}
        customColor={mode === "error" || mode === "warning"}
        onClick={onClick}
        active={isMounted || isShown}
      />

      {(isMounted || isShown) && (
        <NotificationContainer
          isShown={isMounted && isShown}
          height={skipHeightCheck ? 250 : containerHeight}
        >
          {children}
        </NotificationContainer>
      )}
    </ButtonContainer>
  )
}
