import styled, { css } from "styled-components"

import { FullWidth } from "../styled"
import { bgColor } from "../../theme"

export const commonStyle = isLoaded => css`
  width: 100%;
  height: 100%;
  background-color: ${bgColor};

  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0.1)};
  pointer-events: ${({ isLoaded }) => (isLoaded ? "auto" : "none")};

  transition: opacity 0.4s ease-in-out;
`

export const WorldMapContainer = styled(FullWidth)`
  ${({ isLoaded }) => commonStyle(isLoaded)}
`

export const WorldMapContainerFixed = styled.div`
  ${({ isLoaded }) => commonStyle(isLoaded)}
  display: flex;
  justify-content: center;
  align-items: center;
`
