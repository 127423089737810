import { useState, useCallback } from "react"

export function useHover() {
  const [isHover, setHover] = useState(false)
  const handleHoverIn = useCallback(() => setHover(true), [])
  const handleHoverOut = useCallback(() => setHover(false), [])

  return {
    isHover,
    handleHoverIn,
    handleHoverOut,
  }
}
