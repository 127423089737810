import styled from "styled-components"
import { MdLanguage } from "react-icons/md"

import { fgColorSecondary, oldTheme } from "../../theme"
import { TextSecondary } from "../styled"

export const FormattedText = styled(TextSecondary)`
  font-size: 14px;
  padding: 0 0 1px 4px;
  font-style: oblique;

  &:hover {
    text-decoration: underline;
  }
`

export const LanguageIcon = styled(MdLanguage)`
  margin: 0 4px;
`

export const TextButton = styled.button`
  vertical-align: center;
  font-size: 14px;
  padding: 0 0 1px 4px;
  font-style: oblique;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: ${fgColorSecondary};
  background-color: transparent;
  transition: border-bottom 0.2s ease-in-out;

  text-decoration: ${({ isEnabled }) => (isEnabled ? "underline" : "none")};

  &:hover,
  &:focus {
    border-bottom: 2px solid ${oldTheme.warningColor};
  }

  &:active,
  &:focus {
    outline: none;
  }
`
