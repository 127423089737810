import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { InputText } from "../../styled"
import { Range } from "../../range"
import { plural } from "../../../utils"
import { setCont, setPeriod, setVirus } from "../../../data/actions"
import { useLanguage } from "../../../hooks"

export function VirusConfig() {
  const { lang, t } = useLanguage()
  const dispatch = useDispatch()
  const { name, feature, virus, period, cont } = useSelector(
    ({ settings }) => settings
  )

  const handleChangeVirus = useCallback(
    e => {
      const value = e.target.value
      if (value.length >= 15) {
        return
      }
      dispatch(setVirus(value))
    },
    [dispatch]
  )

  const handleChange = useCallback(
    action => e => dispatch(action(e.target.value)),
    [dispatch]
  )

  return (
    name &&
    name.name.en === "Virus" &&
    feature && (
      <>
        <InputText
          placeholder={t("settings.virus.placeholder")}
          value={virus}
          onChange={handleChangeVirus}
          notFilled={!virus}
        />
        <Range
          min={1}
          max={30}
          step={1}
          value={period}
          onChange={handleChange(setPeriod)}
          label={t("settings.virus.incubate")}
          labelValue={`${period} ${plural(
            period,
            {
              ru: ["день", "дня", "дней"],
              en: ["day", "days", "days"],
            },
            lang
          )}`}
        />
        <Range
          min={0.1}
          max={1}
          step={0.1}
          value={cont}
          onChange={handleChange(setCont)}
          label={t("settings.virus.cont")}
          labelValue={cont}
        />
      </>
    )
  )
}
