import React from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import { GiMaterialsScience, GiMeteorImpact } from "react-icons/all"

import { SettingsMenuItem } from "../menu"
import { setMaterial, setSpaceObject } from "../../../data/actions"
import { iconProps } from "../../../constants"
import { useLanguage } from "../../../hooks"

export function AsteroidFeature({ handlers, receivedData }) {
  const { handleDispatch, handleLoad } = handlers
  const { t, lang } = useLanguage()
  const { name, feature, spaceObject, material } = useSelector(
    ({ settings }) => settings
  )

  return (
    <>
      {name &&
        name.name.en === "Celestial body" &&
        receivedData.spaceObjects &&
        feature &&
        feature.name.en !== "Meteorite" && (
          <SettingsMenuItem
            title={t(
              `settings.disaster.Celestial body.spaceObject.${feature.name.en}.value`
            )}
            description={
              (spaceObject && spaceObject.name[lang]) ||
              t(
                `settings.disaster.Celestial body.spaceObject.${feature.name.en}.description`
              )
            }
            notFilled={!spaceObject || !spaceObject.name[lang]}
            data={receivedData.spaceObjects}
            id="idSpaceObject"
            onLoad={_.noop}
            onClick={handleDispatch(setSpaceObject)}
            icon={<GiMeteorImpact {...iconProps} />}
          />
        )}

      {name &&
        name.name.en === "Celestial body" &&
        receivedData.spaceObjects && (
          <SettingsMenuItem
            title={t(
              `settings.disaster.Celestial body.spaceObject.material.value`
            )}
            description={
              (material && material.name[lang]) ||
              t(
                `settings.disaster.Celestial body.spaceObject.material.description`
              )
            }
            notFilled={!material || !material.name[lang]}
            data={receivedData.materials}
            id="idMaterial"
            onLoad={handleLoad("material")}
            onClick={handleDispatch(setMaterial)}
            icon={<GiMaterialsScience {...iconProps} />}
          />
        )}
    </>
  )
}
