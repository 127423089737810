import styled from "styled-components"
import { Button, FlexCentered } from "../styled"
import { bgColor, fgColorSecondary, lgShadow } from "../../theme"

export const FooterPanel = styled(FlexCentered)`
  z-index: 2;
  box-sizing: border-box;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: -1px;
  left: 0;
  width: 100vw;
  height: 30px;
  box-shadow: ${lgShadow};
  background-color: ${bgColor};
  padding: 0 20px;

  color: ${fgColorSecondary};

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  @media (max-width: 520px) {
    padding: 0;
  }
`

export const FooterGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
`

export const FooterButton = styled(Button)`
  padding: 3px;
  width: 120px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
`
