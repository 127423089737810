import React from "react"
import { navigate } from "gatsby"

import { formatNumber } from "./format-number"
import { IconButton } from "../components"

const disasterOnClick = params => () => {
  const searchUrl = Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&")
  navigate(`/start?${searchUrl}`)
}

const newDisaster = (disaster, lang, t) => {
  if (!disaster) {
    return null
  }
  const {
    mode: {
      duration,
      scale,
      disaster: { lvl },
      bomb,
      virus,
      location,
      region,
    },
    date,
    damage: { deaths, victim },
  } = disaster

  return {
    name:
      disaster?.mode?.disaster?.disasterType &&
      disaster?.mode?.disaster?.disasterType[`name_${lang}`],
    virus: virus ? virus.classVirus[`name_${lang}`] + ` - ${virus.name}` : "-",
    virusProps: virus
      ? t("reports.result.virusMeasure", {
          inc: virus.incPeriod,
          cont: virus.contagiousness,
        })
      : "-",
    bomb: bomb ? bomb[`name_${lang}`] : "-",
    power: lvl && lvl[`name_${lang}`],
    duration: duration && duration[`name_${lang}`],
    scale: scale && scale[`name_${lang}`],
    country: location && location[`name_${lang}`],
    region: region ? region[`name_${lang}`] : "-",
    deaths: formatNumber(String(deaths)),
    victim: formatNumber(String(victim)),
    date,
    start: (
      <IconButton
        iconName="reportsPlay"
        autoWidth
        onClick={disasterOnClick(disaster.damage)}
      />
    ),
  }
}

export function makeReportData(data, lang, t) {
  if (!data || !data.length) {
    return []
  }
  return data.map(disaster => ({
    ...newDisaster(disaster, lang, t),
  }))
}
