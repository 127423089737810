export function formatNumber(str) {
  const strArray = str.split("").reverse()
  let pos = 0
  const newArray = strArray.reduce((array, char, idx) => {
    if (!(idx % 3)) {
      pos += 1
    }

    array[pos] = `${char}${array[pos] || ""}`

    return array
  }, [])

  return newArray.reverse().join("\u00A0")
}
