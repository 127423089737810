import React, { useState, useCallback } from "react"
import styled from "styled-components"

import { TextSecondary, FlexCentered } from "../../../components"
import { TextButton } from "../../footer/text.styled"
import { useLanguage } from "../../../hooks"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`

export function Symptom({ title, description }) {
  const { t } = useLanguage()
  const [isDescriptionShown, setDescriptionShown] = useState(false)
  const handleOpen = useCallback(() => setDescriptionShown(value => !value), [])

  return (
    <Container>
      <FlexCentered
        css={`
          width: 100%;
        `}
      >
        <TextSecondary>{title}</TextSecondary>
        <TextButton isEnabled={isDescriptionShown} onClick={handleOpen}>
          {t("settings.disaster.further")}
        </TextButton>
      </FlexCentered>

      {isDescriptionShown && <TextSecondary>{description}</TextSecondary>}
    </Container>
  )
}
